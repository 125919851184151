import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Slider = loadable(() => import("react-slick"));
const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const SofttikLife = loadable(() => import("../components/softtik-life"));
const EventSofttik = loadable(() => import("../components/event-softtik"));
const Contact = loadable(() => import("../components/contact"));

export class LifeAtSofttik extends Component {

    render() {

        const settings_one = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: true,
            autoplaySpeed: 3000,
            loop: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        dots: false,
                        infinite: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                        infinite: true
                    }
                }
            ]
        };

        const slider_three = {
            dots: false,
            infinite: true,
            speed: 500,
            autoplay: false,
            autoplaySpeed: 3000,
            loop: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        dots: false,
                        infinite: true
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false,
                        infinite: true
                    }
                }
            ]
        };

        return (
            <>
                <InnerLayout header='Main' fallback={<Loader />}>
                    <Head
                        type="Website"
                        fallback={<Loader />}
                        path="/life-at-softtik/"
                        title="Forward Thinking Firm Offering Blockchain Solutions - Softtik"
                        description="At Softtik, we are one big family that brings profound, positive change with the creative freedom to ideate and innovate state-of-the-art blockchain solutions.    "
                    />
                    <main>
                        <div id="wrapper">
                            <div className="collection-page life-at-softtik-page">
                                {/* Banner */}
                                <section className="banner-sec lifeat-softtik-age">
                                    <div className="banner-content">
                                        <div className="auto-container">
                                            <div className='row'>
                                                <div className="col-lg-6 col-md-12 col-sm-12 ">
                                                    <div className='banner-text wow fadeIn' data-wow-delay="0.3s">
                                                        <p className="subheding">Softtik Technologies </p>
                                                        <h1>An exciting, lively, & Vibrant place to work.</h1>
                                                        <p>Softtik is a leading NFT game development company with years of experience, providing end-to-end stellar gaming solutions. Offer thrill and excitement to the players by leveraging our state-of-the-art services. Our developers' in-depth knowledge and vast experience can help you create spectacular NFT-based games. We offer impressive, secure, and reliable; 2D NFT game development solutions that can elevate your business. Get in touch with us to outperform your competition with the most engaging and interactive gaming solutions.</p>
                                                        <Link to={`#get-intouch`} title="Check it out" className="stripped btn-style-one" stripHash>
                                                            Check it out <i className='icon fa fa-angle-right'></i>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Team */}
                                    <div className='team-sec'>
                                        <div className='auto-container'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='sec-title text-center'>
                                                        <h2>Core Team</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row team-top wow fadeIn' data-wow-delay="0.3s">
                                                <div className='team-block col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-1'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-1.webp" alt="Muhammad Mousa" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Muhammad Mousa</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='team-block col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-2'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-2.webp" alt="Talha Javaid Malik" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Talha Javaid Malik</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='team-block col-lg-4 col-md-12 col-sm-12'>
                                                    <div className='team-iner team-3'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-3.webp" alt="Ammar Yasir" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Ammar Yasir</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div >

                                            <div className='row team-center wow fadeIn' data-wow-delay="0.3s">
                                                <div className='team-block col-lg-3 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-4'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-4.webp" alt="Muhaddis Umer" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Muhaddis Umer</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='team-block col-lg-3 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-5'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-5.webp" alt="Khizar Sattar" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Khizar Sattar</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='team-block col-lg-3 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-6'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-6.webp" alt="Raazia Syed" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Raazia Syed</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='team-block col-lg-3 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-7'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-8.webp" alt="Muhammad Bilal" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Muhammad Bilal</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row team-bottom wow fadeIn' data-wow-delay="0.3s">
                                                <div className='team-block col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-8'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-7.webp" alt="Hafsa Arshad" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Hafsa Arshad</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='team-block col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-9'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-9.webp" alt="Ahmad Banoori" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Ahmad Banoori</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='team-block col-lg-4 col-md-12 col-sm-12'>
                                                    <div className='team-iner team-10'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-10.webp" alt="Noman Ajmal" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Noman Ajmal</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row team-bottom-under wow fadeIn' data-wow-delay="0.3s">

                                                <div className='team-block col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-11'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-11.webp" alt="Muhammad Asif" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>M Asif</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='team-block col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-13'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-13.webp" alt="Taimoor Malik" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Umar Khan</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='team-block col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='team-iner team-12'>
                                                        <div className='img-inner'>
                                                            <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-12.webp" alt="Taimoor Malik" />
                                                        </div>
                                                        <div className='text-area'>
                                                            <h5>Taimoor Malik</h5>
                                                        </div>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>
                                    </div>

                                    <div className='team-sec mobile-team'>
                                        <div className='auto-container'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='sec-title text-center'>
                                                        <h2>Core Team</h2>
                                                    </div>

                                                    <Slider {...settings_one}>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-1'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-1.webp" alt="Muhammad Mousa" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Muhammad Mousa</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-2'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-2.webp" alt="Talha Javaid Malik" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Talha Javaid Malik</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-3'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-3.webp" alt="Ammar Yasir" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Ammar Yasir</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-4'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-4.webp" alt="Muhaddis Umer" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Muhaddis Umer</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-5'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-5.webp" alt="Khizar Sattar" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Khizar Sattar</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-6'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-6.webp" alt="Raazia Syed" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Raazia Syed</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-7'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-8.webp" alt="Muhammad Bilal" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Muhammad Bilal</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-8'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-7.webp" alt="Hafsa Arshad" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Hafsa Arshad</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-9'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-9.webp" alt="Ahmad Banoori" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Ahmad Banoori</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-10'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-10.webp" alt="Noman Ajmal" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Noman Ajmal</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-11'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-11.webp" alt="Muhammad Asif" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>M Asif</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-13'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-13.webp" alt="Taimoor Malik" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Umar Khan</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className='team-block'>
                                                                <div className='team-iner team-12'>
                                                                    <div className='img-inner'>
                                                                        <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-12.webp" alt="Taimoor Malik" />
                                                                    </div>
                                                                    <div className='text-area'>
                                                                        <h5>Taimoor Malik</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Slider>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </section>

                                <section className='team-sec-slider'>
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='sec-title text-center'>
                                                    <h2>Blockchain Developers</h2>
                                                    <p>We have a competent and highly skilled blockchain development team who have mastered the art over the years by handling various clients from across the globe. These are independent, prosperous, and responsible individuals who strive to provide value-adding solutions to our clients. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Slider {...slider_three}>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-1'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-2.webp" alt="Talha Javaid Malik" />
                                                                    <h5>Talha Javaid Malik</h5>
                                                                    <p>Blockchain Developer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-2'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-12.webp" alt="Taimoor Malik" />
                                                                    <h5>TAIMOOR MALIK</h5>
                                                                    <p>Blockchain Developer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-3'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-8.webp" alt="Muhammad Bilal" />
                                                                    <h5>Muhammad Bilal</h5>
                                                                    <p>Blockchain Developer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-1'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-16.webp" alt="Muhammad Mousa" />
                                                                    <h5>Shaeer</h5>
                                                                    <p>Blockchain Developer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-1'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-2.webp" alt="Talha Javaid Malik" />
                                                                    <h5>Talha Javaid Malik</h5>
                                                                    <p>Blockchain Developer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-2'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-12.webp" alt="Taimoor Malik" />
                                                                    <h5>TAIMOOR MALIK</h5>
                                                                    <p>Blockchain Developer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-3'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-8.webp" alt="Muhammad Bilal" />
                                                                    <h5>Muhammad Bilal</h5>
                                                                    <p>Blockchain Developer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-2'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-16.webp" alt="Muhammad Mousa" />
                                                                    <h5>Shaeer</h5>
                                                                    <p>Blockchain Developer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className='team-sec-slider style-two'>
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='sec-title text-center'>
                                                    <h2>Game Developers</h2>
                                                    <p>Softtik is fortunate to have a clan of game developers who provide reliable, engaging, and state-of-the-art game development services & solutions. These highly motivated individuals have come far with their passion and learning ability. </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Slider {...slider_three}>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-1'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-22.webp" alt="Hamza Mir" />
                                                                    <h5>Hamza Mir</h5>
                                                                    <p>Team Lead</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-2'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-18.webp" alt="Hamza Mir" />
                                                                    <h5>Danish</h5>
                                                                    <p>Sr Unity Developer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-1'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-20.webp" alt="Muhammad Mousa" />
                                                                    <h5>M Rehman</h5>
                                                                    <p>Sr Animator</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-2'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-21.webp" alt="Azhar" />
                                                                    <h5>Azhar</h5>
                                                                    <p>Unity Developer</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-3'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-19.webp" alt="Rustam Rehman" />
                                                                    <h5>Rustam Rehman</h5>
                                                                    <p>Jr Animator</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className='team-sec-slider style-three'>
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='sec-title text-center'>
                                                    <h2>Marketing Team</h2>
                                                    <p>Our marketing stars enable us to spread positive change and collaborate with potential clients. Our cross-capability experts share knowledge and work together as a team to achieve a common goal – for our clients and ourselves.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='container-fluid'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <Slider {...slider_three}>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-1'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-23.webp" alt="Rafiullah" />
                                                                    <h5>Rafiullah</h5>
                                                                    <p>SEO Specialist</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-2'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-10.webp" alt="Noman Ajmal" />
                                                                    <h5>Noman</h5>
                                                                    <p>Marketing Manager</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-3'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-14.webp" alt="Waqar" />
                                                                    <h5>Waqar</h5>
                                                                    <p>Social Media Executive</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-1'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-15.webp" alt="Shahid" />
                                                                    <h5>Shahid</h5>
                                                                    <p>Blockchain Wirtter</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-2'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-23.webp" alt="Rafiullah" />
                                                                    <h5>Rafiullah</h5>
                                                                    <p>SEO Specialist</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-3'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-10.webp" alt="Noman Ajmal" />
                                                                    <h5>Noman</h5>
                                                                    <p>Marketing Manager</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-1'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-14.webp" alt="Waqar" />
                                                                    <h5>Waqar</h5>
                                                                    <p>Social Media Executive</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className='team-block'>
                                                            <div className='team-iner team-2'>
                                                                <div className='img-inner'>
                                                                    <img className='lazyload' src="https://assets-softtik.s3.us-east-2.amazonaws.com/images/team-15.webp" alt="Muhammad Mousa" />
                                                                    <h5>Shahid</h5>
                                                                    <p>Blockchain Wirtter</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Slider>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                {/* cases */}
                                <section className='cases-tabs-sec style-three lifeat-softtik-tabs' >
                                    <EventSofttik />
                                </section>

                                <section className='lifesofttik-sec'>
                                    <SofttikLife />
                                </section>

                                <section className='get-intouch-sec' id="get-intouch">
                                    <Contact />
                                </section>
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default LifeAtSofttik;